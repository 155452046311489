<template>
    <v-container fluid>
        <div class="mx-auto lg:tw-w-1/2">
            <v-card class="mb-4">
                <v-card-title>Change password</v-card-title>
                <v-card-text>
                    <v-text-field
                        type="password"
                        name="current_password"
                        v-model="form.current"
                        :error-messages="form.errors.get('current')"
                        @input="form.errors.clear('current')"
                        label="Current Password"
                        autofocus
                    />
                    <v-text-field
                        type="password"
                        name="new_password"
                        v-model="form.new"
                        :error-messages="form.errors.get('new')"
                        @input="form.errors.clear('new')"
                        label="New Password"
                    />
                    <v-text-field
                        type="password"
                        name="new_password_confirmation"
                        v-model="form.new_confirmation"
                        :error-messages="form.errors.get('new_confirmation')"
                        @input="form.errors.clear('new_confirmation')"
                        label="Confirm New Password"
                    />
                    <div class="d-flex align-center">
                        <v-spacer />
                        <v-btn color="primary" :loading="saving" @click="save">Save</v-btn>
                    </div>
                </v-card-text>
            </v-card>
            <linked-accounts />
        </div>
    </v-container>
</template>

<script>
import Http from '@/utils/httpClient';
import Form from '@/utils/form';

export default {
    components: {
        LinkedAccounts: () => import('@/components/LinkedAccounts.vue')
    },
    data() {
        return {
            saving: false,
            form: new Form({
                current: null,
                new: null,
                new_confirmation: null
            })
        };
    },
    methods: {
        save() {
            this.saving = true;
            Http.patch('settings/password', this.form.payload())
                .then(() => {
                    this.saving = false;
                    this.form.reset();
                    this.$root.$snackbar.open({
                        text: 'Password was successfully updated.',
                        type: 'success'
                    });
                })
                .catch(({ response }) => {
                    this.saving = false;
                    this.form.errors.record(response.data);
                });
        }
    }
};
</script>
